import React from "react";
import { Helmet } from "react-helmet";

export default ({ title, url }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <link rel="canonical" href={url} />
    </Helmet>
  );
};
